

import star from '../img/start.svg'
import logo from '../img/vakan__item_logo.png'



const VakanDataCard = [

    {
        logo: logo,
        title: 'Егорова Анна',
        subtitle: 'Ищет работу',
        prov: 'Web-дизайнер',
        provOpt: '3',
        star: star,
        linkText : 'Подробнее',
        link : '/', 
        num: '0.5'

    } ,

    {
        logo: logo,
        title: 'Егорова Анна',
        subtitle: 'Ищет работу',
        prov: 'Web-дизайнер',
        provOpt: '3',
        star: star,
        linkText : 'Подробнее',
        link : '/', 
        num: '0.5'

    } ,

    {
        logo: logo,
        title: 'Егорова нна',
        subtitle: 'Ищет работу',
        prov: 'Web-дизайнер',
        provOpt: '3',
        star: star,
        linkText : 'Подробнее',
        link : '/', 
        num: '0.5'

    } ,

    
 
]


export default VakanDataCard