

import logo from '../components/img/Logo3.svg'
import glaz from '../components/img/glaz.svg'
import deletet from '../components/img/delete.svg'
import izmen from '../components/img/izmen.svg'


const MyDataCardTwo = [

    {
        pros: 55,
        CardIdTwo: 1 ,
        vakan: 'Большой театр' ,

        otSena: '40 000' ,
        doSena: '120 000' ,



        opetrabot: 'от 1 года' ,
        proves: 'Певцы' ,
        provesTwo: 'Актеры' ,
        provesThree: 'Учителя' ,
        zynytost: 'Полная занятость, полный рабочий день ' ,

        NameKopmany: 'Актер в Моссковский Большой театр' ,

        Grafic : 'Полный день' ,

        AboutCompany: 'Работа в Большом театре в центре Москвы Нормированный график 5 На 2 с 8:30 до 18:30 Нормированный график 5/2 с 8:30 до 18:30; Высокая заработная плата без задержек и потолка' ,

        Trebovanie: 'Работать с клиентами по телефону Стабильно посещать офис без опозданий; Оформлять заказы, бронировать места для зрителей (учим); Ежедневно учиться и выполнять домашнее задание по изучению продукта; Посещать культурные и обучающие мероприятия компании. ' ,

        Uslovie : 'удаленная работа; развития через новые технологии (другие CMS) и сложные проекты; полная занятость / частичная занятость / возможность совмещения (от 2 часов в день); крутая команда; заработная плата до 60 000 рублей, простая система мотивации (от объёма отработанных часов, выплаты 2 раза в месяц); стабильность и возможность зарабатывать.' ,



        glaz:glaz ,
        logo:logo ,
        izmen:izmen ,
        deletet:deletet ,
    } ,

    {
        pros: 55,
        CardIdTwo: 2 ,
        vakan: 'Продавец ' ,

        otSena: '10 000' ,
        doSena: '30 000' ,



        opetrabot: 'от 3 года' ,
        proves: 'Певцы' ,
        provesTwo: 'Актеры' ,
        provesThree: 'Учителя' ,
        zynytost: 'Полная занятость, полный рабочий день ' ,

        NameKopmany: 'Тинькофф' ,

        Grafic : 'Полный день' ,

        AboutCompany: 'Работа в Большом театре в центре Москвы Нормированный график 5 На 2 с 8:30 до 18:30 Нормированный график 5/2 с 8:30 до 18:30; Высокая заработная плата без задержек и потолка' ,

        Trebovanie: 'Работать с клиентами по телефону Стабильно посещать офис без опозданий; Оформлять заказы, бронировать места для зрителей (учим); Ежедневно учиться и выполнять домашнее задание по изучению продукта; Посещать культурные и обучающие мероприятия компании. ' ,

        Uslovie : 'удаленная работа; развития через новые технологии (другие CMS) и сложные проекты; полная занятость / частичная занятость / возможность совмещения (от 2 часов в день); крутая команда; заработная плата до 60 000 рублей, простая система мотивации (от объёма отработанных часов, выплаты 2 раза в месяц); стабильность и возможность зарабатывать.' ,



        glaz:glaz ,
        logo:logo ,
        izmen:izmen ,
        deletet:deletet ,
    } ,
    

    
 
]


export default MyDataCardTwo