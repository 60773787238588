

import star from '../img/start.svg'
import sber from '../img/sber.svg'
import teart from '../img/teatr.svg'
import bau from '../img/bau.svg'



const KompDatacard = [

    {
        logo: sber,
        title: 'Сбер',
        subtitle: '2 открытые вакансии Оформление по тк',
        star: star,
        num: '5.0',
        link: '/',
        linkText : 'Подробнее'

    } ,

    {
        logo: teart,
        title: 'Большой Театр',
        subtitle: '4 открытые вакансии Оформление по тк',
        star: star,
        num: '5.0',
        link: '/',
        linkText : 'Подробнее'

    } ,

    {
        logo: bau,
        title: 'Бауман бар',
        subtitle: '1 открытые вакансии Оформление по тк',
        star: star,
        num: '5.0',
        link: '/',
        linkText : 'Подробнее'

    } ,

    

    

 
]
export default KompDatacard